.services {
  overflow: hidden;
}

.service-container {
  margin-top: 5rem;

  color: black;
  max-width: 100%;

  z-index: 22;
}

.title-wrapper {
  margin-block-end: 40px;
  font-family: "Ubuntu";
}

.title-wrapper .section-text {
  font-weight: 500;
  margin-top: 5px;
}
.service-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
}
.my-service {
  background-color: white;
  margin-bottom: 10px;
  position: relative;
  width: 280px;
  border-radius: 10px !important;
  padding-top: 10px;
  display: flex;
  height: 240px;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  text-align: center;

  transition: all 0.5s ease;
}
.my-service:hover {
  transform: scale(1.06);
  box-shadow: 0px 30px 60px 0px rgb(0 0 0 / 20%) !important;
  transition: all 0.2s cubic-bezier(0.98, 0.57, 1, 1) 0s;
}
.my-service .web-title {
  font-family: "ubuntu";
  margin: 10px 0;
  font-size: 17px;
  color: black;
}
.ser-back {
  display: flex;
  font-size: 28px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  color: rgb(2, 12, 27);
  margin-bottom: 20px;
  background: rgb(188, 212, 233);
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
}
.my-service img {
  margin: 10px 0;
  width: 50px;
}
.my-service:hover .web-title {
  color: #1473bc !important;
}
.my-service span {
  font-size: 3rem;
  margin-top: 30px;
  margin-bottom: 20px;
}
.service-info {
  text-align: center;
  font-family: "poppins";
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}
/* .service-title {
  font-family: "Ubuntu", sans-serif;
  margin-bottom: 60px;
  text-align: center;
}
.service-title h2 {
  font-size: 14px;
  font-weight: 300;
  color: var(--color-title);
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
  color: var(--color-title);
  font-weight: 600;
}
.service-title h3 {
  font-size: 2rem;
  text-transform: capitalize;
} */

.my-service .web {
  color: #fff !important;
  font-weight: 700;
}

.shadow-icon {
  font-size: 195px;
  position: absolute;
  right: -90px;
  bottom: -100px;
  opacity: 0.05;
  z-index: 0;
}

/* Responsive */
/* @media only screen and (max-width: 1738px) {
  .service-container {
    padding: 100px 100px;
  }
  .service-title h2 {
    font-size: 2rem;
  }
  .my-service {
    width: 500px !important;
    padding: 40px 20px !important;
    margin-bottom: 20px !important;
    height: 400px;

    margin: auto;
    margin-bottom: 1.5rem;

    width: 40%;
  }
  .my-service .web {
    margin: 10px 0;
    font-size: 2.4rem;
  }
  .service-title h3 {
    font-size: 4rem;
    width: 500px;
  }
  .my-service img {
    margin: 10px 0;
    width: 40px;
  }

  .service-info {
    margin-bottom: 30px;
    font-size: 1.8rem;
    font-weight: 300;
  }
} */
@media only screen and (max-width: 1024px) {
  .my-service {
    padding: 10px 10px;
    /* margin-right: 10px; */
    margin: auto;
    margin-bottom: 1.5rem;

    width: 40%;
  }
}
@media only screen and (max-width: 768px) {
  .service-title {
    text-align: center;
  }
  .service-title h3 {
    font-size: 2rem;
  }
  .service-row {
    justify-content: space-around;
  }
  .my-service {
    margin: auto;
    margin-bottom: 1.5rem;

    width: 40%;
  }
}

@media only screen and (max-width: 580px) {
  .my-service {
    width: 90%;

    margin: auto;
    margin-bottom: 1.5rem;
  }
}

@media only screen and (max-width: 377px) {
  /* .service-container {
    padding: 30px 20px !important ;
  } */
  .my-service {
    width: 300px;
    margin: auto;
    margin-bottom: 1.5rem;
  }
}

/* light theme */
.light .my-service {
  box-shadow: 0px 30px 60px 0px rgb(0 0 0 / 10%) !important;
}
.light .my-service:hover {
  box-shadow: var(--card-box-shadow);
  font-weight: 700 !important;
}
.light .my-service .web {
  color: black !important;
  font-weight: 700;
}
.light .my-service:hover .web {
  color: #fb7878 !important;
}
.light .service-title h3 {
  color: rgb(34, 34, 34) !important;
}
.light .ser-back {
  background: #e0e0e0;
}

/* font-family: 'Bebas Neue', cursive;
font-family: 'Gloock', serif;
font-family: 'Playfair Display', serif;
font-family: 'Poppins', sans-serif;
font-family: 'Redressed', cursive;
font-family: 'Roboto', sans-serif;
font-family: 'Ubuntu', sans-serif;
 */
