.con {
  overflow: hidden;
}

.section-title {
  margin-top: 5rem;
}

.section-title h2 {
  font-family: "Ubuntu";
  margin-bottom: 2rem;
}
.container {
  font-family: "Poppins";
  color: black;
  width: 79%;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 20px 60px 30px 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin: auto;
  margin-bottom: 5rem;
}
.container .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container .content .left-side {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 15px;
  position: relative;
}

.left-side a {
  text-decoration: none;
  color: #3874c7;
}
.content .left-side::before {
  content: " ";
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #3874c7;
}
.content .left-side .details {
  margin: 14px;
  text-align: center;
}
.content .left-side .details i {
  font-size: 30px;
  color: #3e2093;
  margin-bottom: 10px;
}
.content .left-side .details .topic {
  font-size: 18px;
  font-weight: 500;
}
.content .left-side .details .text-one,
.content .left-side .details .text-two {
  font-size: 14px;
  color: #0c0c0c;
}
.container .content .right-side {
  width: 75%;
  margin-left: 75px;
}
.content .right-side .topic-text {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 800;
  font-family: "Poppins";
  color: #3874c7;
}

.c-paragraph {
  font-size: 15px;
  font-weight: 500;
}
.right-side .input-box {
  overflow: hidden;
  border: none;
  border-radius: 4px;
  height: 50px;
  width: 100%;
  margin: 12px auto;
}
.right-side .input-box input,
.right-side .input-box textarea {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: rgb(241, 243, 245);
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}
.right-side .message-box {
  min-height: 110px;
}
.right-side .input-box textarea {
  padding-top: 6px;
}
.right-side .c-button {
  display: inline-block;
  margin-top: 12px;
}
.c-img {
  height: 45px;
  transition: 0.6s;
}

.c-img:hover {
  transform: scale(1.2);
}
.right-side .c-button input[type="button"] {
  font-family: "Ubuntu";
  background-color: #3874c7; /* Green background color */
  border: none; /* Remove the default button border */
  color: white; /* Text color */
  padding: 12px 24px; /* Add some padding */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Display as a block element */
  font-size: 16px; /* Font size */
  border-radius: 4px; /* Add some rounding */
  transition: background-color 0.3s ease; /* Add a smooth transition */
}
.c-button input[type="button"]:hover {
  background-color: #6697db;
  cursor: pointer;
}

@media only screen and (max-width: 720px) {
  .container {
    width: 70%;
    padding: 30px 40px 40px 35px;
  }
  .container .content .right-side {
    width: 75%;
  }
}
@media (max-width: 820px) {
  .container {
    margin: 40px auto;
    height: 100%;
  }
  .container .content {
    flex-direction: column-reverse;
  }
  .container .content .left-side {
    width: 100%;
    flex-direction: row;
    margin-top: 40px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .container .content .left-side::before {
    display: none;
  }
  .container .content .right-side {
    width: 100%;
    margin-left: 0;
  }
}
