.Mobilemenu {
  background-image: linear-gradient(
    to right bottom,
    #f2ecff,
    #efebf9,
    #ebe9f3,
    #e9e8ec,
    #e6e6e6
  );
  height: 100vh;
  width: 30vh;
  position: absolute;
  right: 0px;
  top: -6px;

  padding: 18px 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.close-icon {
  display: flex;

  justify-content: flex-end;
}

.mobile-options {
  display: flex;

  flex-direction: column;
  padding-top: 24px;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.mobile-option {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 16px;
  margin: 12px 0px;
  display: flex;
  font-weight: bold;
}

.mobile-option a {
  text-decoration: none;
  color: black;
  display: flex;
}

.mobile-option-img {
  display: flex;
  align-items: center;
  margin: 4px;
  width: 23px;
}
