.about {
  font-family: "Poppins";
  width: 90%;

  padding: 10px;
  margin: 10px auto;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin-bottom: 0px;
  padding-top: 115px;
}

.hero-banner {
  max-width: 500px;
  width: 100%;

  margin-bottom: 30px;
}

.hero-content h2 {
  max-width: 100%;

  font-size: 3em;
  color: #d0e1fa;
  text-shadow: 2px 2px #000;

  padding: 20px;
  border-radius: 10px;

  transition: all 0.3s ease-in-out;
}

.hero-content h2:hover {
  transform: scale(1.1);
}

.hero-title {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 30px;
}
.btn {
  max-width: max-content;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 10px 25px;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: 0.25s ease-in-out;
}

.btn-primary {
  border-color: white;
}

.btn-primary:is(:hover, :focus) {
  background: white;
  color: hsl(0, 0%, 12%);
}
.hero .btn-primary {
  margin-inline: auto;
}

@media only screen and (min-width: 992px) {
  .hero {
    position: relative;
    padding-top: 0;
    height: 100vh;
  }

  .hero-banner {
    max-width: unset;
    height: 100%;
    margin-bottom: 0;
  }

  .hero img {
    height: 100%;
    width: 57%;
    margin-inline: auto;
  }

  .hero-content {
    color: hsl(0, 0%, 100%);

    position: absolute;
    top: 43%;
    filter: drop-shadow(2px 4px 10px var(--black));
  }

  .hero .btn-primary:is(:hover, :focus) {
    color: hsl(0, 0%, 12%);
  }
}

@media only screen and (max-width: 450px) {
  .hero {
    position: relative;
    padding-top: 0;

    min-height: 80vh;
  }

  .hero-banner {
    max-width: unset;
    height: 100%;
    margin-bottom: 0;
  }

  .hero img {
    height: 100%;
    width: 100%;
    margin-inline: auto;
  }

  .hero-content h2 {
    max-width: 650px;
    font-size: 2rem;
  }

  .hero-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 550px) {
  .hero {
    position: relative;
    padding-top: 0;
  }

  .hero-banner {
    margin-bottom: 0;
  }

  .hero img {
    height: 100%;
    width: 100%;
    margin-inline: auto;
  }

  .hero-content h2 {
    max-width: 450px;
    font-size: 1.9rem;
  }

  .hero-title {
    text-align: center;

    margin-bottom: 30px;
  }
}
