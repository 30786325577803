/* New CSS */

.mywork {
  padding: 0 0px 0 0px;
  margin-top: 5rem;
  overflow: hidden;
}

.title-wrapper {
  margin-block-end: 40px;
  font-family: "Ubuntu";
}

.title-wrapper .section-text {
  font-weight: 500;
  margin-top: 10px;
}

.project-links {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.project-links a {
  text-decoration: none;
}
.mywork-title h3 {
  font-size: 2.2rem;

  margin-bottom: 30px;
}
.project {
  background: #ffffff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 17px;
  padding: 20px;

  margin: 10px auto;
  width: 340px;
  height: auto;
}
.project-img {
  overflow: hidden;
  display: block;
  position: relative;
  border-radius: 20px !important;
}
.project-row {
  display: flex;

  align-items: center;
  flex-wrap: wrap;
}
.work-img {
  width: 100%;
  display: inline-block !important;
  border-radius: 20px !important;
  transition: 0.5s;
  height: 220px;
  cursor: pointer;
  transform: scale(1) !important;
}
.project:hover .work-img {
  transform: scale(1.06) !important;
}

.date-posted {
  display: flex !important;
  align-items: center !important;
  padding: 10px 0;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.work-details {
  padding: 8px 4px 4px 3px;
  position: relative;
}
.work-details h2 {
  font-family: "Ubuntu", sans-serif;
  font-size: 1.3rem;
  margin-bottom: 20px;
  max-height: 100px;
  font-weight: 700;
}
.work-details p {
  font-size: 14px;
  font-family: "Poppins";

  font-weight: 400;
  line-height: 1.2rem;
  white-space: normal;
}

.who-post {
  display: flex;
  justify-content: center;
  align-items: center;
}
.who-post p {
  text-align: start;

  text-transform: uppercase;
  font-family: "Poppins";
  font-size: 12px;

  cursor: pointer;
  font-weight: 600 !important;
}

.project .learnmore {
  background: none;
  padding-right: 10px;
  display: flex;

  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding-top: 10px;
  font-family: "Ubuntu", sans-serif;
  text-transform: uppercase;
  font-weight: 800;

  cursor: pointer;
  /* border: 1px solid var(--color-button-hover) !important; */
  color: #3874c7;
  border-radius: 4px;
}
.learnmore img {
  margin-bottom: 2.1px;
}

.learnmore:hover {
  color: #3874c7;
}
.project:hover {
  border-bottom: 4px solid var(--color-button);
}

/*  */

.light .project {
  background: var(--color-card-background);
  box-shadow: var(--card-box-shadow);
  margin-top: 20px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 865px) {
  .project {
    width: 300px;
    margin: 10px auto;
  }
  .mywork .mywork-title {
    text-align: center;
  }
  .mywork .mywork-title h3 {
    font-size: 1.5rem;
  }
  .work-togather-text {
    padding-bottom: 20px;
  }
  .sec-btn {
    font-size: 13px;
  }
  .work-togather-form {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 425px) {
  .mywork-title {
    text-align: center;
  }
  .mywork-title h3 {
    font-size: 1.3rem !important;
    word-wrap: wrap;
  }
  .mywork {
    padding: 0 0 0 0px;
  }
  .project {
    flex-direction: row;
  }
  .work-img {
  }
  .work-details {
    padding: 0 10px 10px 10px;
  }
}
@media only screen and (max-width: 360px) {
  .project {
    padding: 0.7rem;
  }
  .mywork {
    margin: auto;
  }
}

.light .work-details h2 {
  color: black !important;
  font-family: "poppins", sans-serif !important;
}
.light .who-post p {
  color: #ff5652 !important;
}
.light .project .learnmore {
  font-size: 11px;
  color: rgb(5, 5, 5) !important;
}
