.Navbar {
  display: flex;
  flex: 1;
  z-index: 1000;
  position: fixed;

  width: 100%;
  top: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: background-color 0.5s ease;
  animation: slideIn 1.3s ease forwards;
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

* {
  margin: 0;
}

.menu {
  display: flex;
  flex: 1;
}

.mob-menu {
  font-size: 24px;
}

.mobile-menu {
  display: none;
}

.web-menu {
  font-family: "Ubuntu", sans-serif;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-right: 8rem;
}

.title {
  font-family: "Saira Stencil One", cursive;
  display: flex;

  margin-left: 4rem;
  font-size: 32px;
  color: #3874c7;
}

@media only screen and (max-width: 920px) {
  .Navbar {
    width: 100%;
  }

  .mobile-menu {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-right: 0.4rem;
  }

  .web-menu {
    display: none;
  }

  .title {
    font-size: 24px;
    margin-left: 10px;
  }
}
