.Testimonials-container {
  margin: 10px;
  color: #fff;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.title-wrappers {
  overflow: hidden;
  font-family: "Ubuntu";
  margin-bottom: 2rem;
}

.title-wrappers .section-text {
  font-weight: 500;
  margin-top: 5px;
}

.carrousel {
  background: #ffffff;
  text-align: center;
  padding: 4em 0;
  height: 7.5em;
  max-width: 90%;

  margin: auto;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}
.carrousel h2 {
  margin: 0;
  margin-top: -1.7em;
  padding: 0;
  font-size: 1em;
  text-align: center;
  color: #bbbbbb;
}
.carrousel .slides {
  width: 400%;
  left: 0;
  padding-left: 0;
  padding-top: 1em;
  overflow: hidden;
  list-style: none;
  position: relative;

  -webkit-transition: transform 0.8s;
  -moz-transition: transform 0.8s;
  -o-transition: transform 0.8s;
  transition: transform 0.8s;
}
.carrousel .slides li {
  width: 25%;

  position: relative;
  margin-bottom: 2rem;
  float: left;
}
.carrousel li p {
  margin-top: 0;
}
.carrousel li q {
  max-width: 90%;
  margin: auto;
  color: black;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
}

.carrousel li img {
  width: 3em;
  height: 3em;

  object-fit: cover;
  border-radius: 50%;
  margin-left: -1.5em;
  margin-right: 0.5em;
  vertical-align: middle;
}

.carrousel li span.author {
  margin-top: 2rem;

  font-size: 1.2em;
  font-family: "Ubuntu";
  font-weight: 500;
  color: #3874c7;
  display: block;
}

.carrousel .slidesNavigation {
  display: block;
  list-style: none;
  text-align: center;
  bottom: 1em;
  /*--- Centering trick---*/
  /* Absolute positioning*/
  position: absolute;
  /* Abosulte positioning*/
  width: 104px; /*This width  is the addition of the width of all the navigations dots - So in this case is   104 because the navigation dots are 26px (width:10px and 6px marginleft + 6 px marginright) and there are 4 dots so 4x26=104 */
  left: 50%; /*Centering de element*/
  margin-left: -52px; /*adjusting the centering by applying a negative margin of half of the width*/
}
.carrousel input {
  display: none;
}
.carrousel .slidesNavigation label {
  float: left;
  margin: 6px;
  display: block;
  height: 10px;
  width: 10px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: solid 2px #2980b9;
  font-size: 0;
}
/* You have to repeat this with each slide
TODO: make it easier with SCSS
25% movement 100/slides-num
*/
#radio-1:checked ~ .slides {
  transform: translateX(0%);
}
#radio-2:checked ~ .slides {
  transform: translateX(-25%);
}
#radio-3:checked ~ .slides {
  transform: translateX(-50%);
}
#radio-4:checked ~ .slides {
  transform: translateX(-75%);
}

.carrousel .slidesNavigation label:hover {
  cursor: pointer;
}
/* You have to repeat this with each slide/dot */
.carrousel #radio-1:checked ~ .slidesNavigation label#dotForRadio-1,
.carrousel #radio-2:checked ~ .slidesNavigation label#dotForRadio-2,
.carrousel #radio-3:checked ~ .slidesNavigation label#dotForRadio-3,
.carrousel #radio-4:checked ~ .slidesNavigation label#dotForRadio-4 {
  background: #2980b9;
}

@media (max-width: 796px) {
  .carrousel {
    height: 8.3em;
  }
}
@media (max-width: 480px) {
  .carrousel {
    height: 11em;
  }
  .carrousel li p {
    padding-left: 0.5em;

    padding-right: 0.5em;
  }
  .carrousel li q {
    font-size: 1em;
  }
  .carrousel li img {
    width: 2em;
    margin-left: -1em;
    margin-right: 0.25em;
  }

  .carrousel li span.author {
    margin-top: 1rem;

    font-size: 1.2em;
    font-family: "Ubuntu";
    font-weight: 500;
    color: #3874c7;
    display: block;
  }
}
