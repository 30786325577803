.skills {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  padding: 20px 30px 20px 40px;
}

.skills-content {
  width: 80%;
  text-align: start;
}

.skills-content h1 {
  margin-bottom: 10px;
  text-align: start;
  font-family: "Ubuntu";
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 800;
}

.skills-content h2 {
  margin-bottom: 20px;
  text-align: start;
  font-family: "Ubuntu";
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
}

.skills-content p {
  margin-bottom: 20px;
  text-align: start;
  font-family: "Poppins";
  font-size: 15px;
}

.skills-content .section-title {
  font-family: Ubuntu;
}

/* .skills-toggle {
  background: var(--bg-secondary);
  position: relative;
  width: max-content;
  margin-inline: auto;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 100px;
  box-shadow: var(--shadow-1);
  z-index: 1;
}

.skills-toggle::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  bottom: 5px;
  width: 93px;
  background: var(--raw-seinna);
  border-radius: 100px;
  transition: var(--transition-1);
  z-index: -1;
}

.skills-toggle.active::before {
  left: 98px;
  width: 94px;
}

.toggle-btn {
  color: var(--color-primary);
  font-family: var(--ff-poppins);
  padding: 5px 25px;
  transition: var(--transition-1);
}

.toggle-btn.active { color: var(--white); }

.skills-box.active .skills-list,
.skills-box .tools-list { display: none; } */

.skills-box {
  display: flex;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: 3rem;
  margin-left: 11rem;
  justify-content: center;

  gap: 15px;
  animation: fadeIn 0.5s ease-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.skill-card {
  border-radius: 12px;
  box-shadow: 10px 10px 40px hsla(0, 0%, 0%, 0.1);
  background: #ffff;
  width: 80px;
  height: 80px;
  /* display: grid;
  place-items: center; */

  display: flex;
  justify-content: center;
  align-items: center;
}

.card-icon img {
  width: 50px;
  margin-top: 10px;
}

.skill-card:hover .tooltip {
  transform: translateY(0);
  opacity: 1;
}

@media only screen and (max-width: 865px) {
  .skills {
    display: flex;
    flex-direction: column;
  }

  .skills-box {
    flex-wrap: wrap;
    margin: auto;
  }

  .skills-list {
    margin: auto;
  }

  .skills-content {
    width: 100%;
  }

  .skills-content h2 {
    font-size: 1.3rem;
    margin-bottom: 20px;
    line-height: 25px;
    font-size: 24px;
  }
}

@media only screen and (max-width: 425px) {
  .skills {
    margin: auto;

    padding: 0.7rem;
  }

  .skills-box {
    width: 100%;
  }

  .skills-content p {
    font-size: 14px;
  }
}
